<template>
  <div class="bompage">
    <b-container fluid class="content">
      <b-alert
        :show="countdown"
        dismissible
        variant="success"
        @dismissed="countdown = 0"
        @dismiss-count-down="close_alert"
        class="show_alert"
      >
        {{ dismessage }}
      </b-alert>
      <div v-if="currpage == 'list'">
        <b-card>
          <b-form>
            <el-row :gutter="10">
              <el-col :span="4">
                <b-input
                  class="form-control"
                  placeholder="PLU"
                  v-model="cur_PLU"
                  @keyup="get_product_list_delay"
                />
              </el-col>
              <el-col :span="4">
                <b-input
                  class="form-control"
                  :placeholder="$t('Name')"
                  v-model="cur_name"
                  @keyup="get_product_list_delay"
                />
              </el-col>
              <el-col :span="4" v-if="departmentlist">
                <select
                  class="form-control"
                  v-model="cur_department_IDn"
                  @change="bulid_category()"
                >
                  <option :value="0">{{ $t("Select Department") }}</option>
                  <option
                    v-for="(dpt, idx) in departmentlist"
                    :key="idx"
                    :value="dpt.IDn"
                  >
                    {{ dpt.Name1 }}-{{ dpt.Name2 }}
                  </option>
                </select>
              </el-col>
              <el-col :span="4">
                <select
                  class="form-control"
                  v-model="cur_category_IDn"
                  @change="get_product_list_delay"
                >
                  <option :value="0">{{ $t("Select Category") }}</option>
                  <option
                    v-for="(ct, idx) in categorylist"
                    :key="idx"
                    :value="ct.IDn"
                  >
                    {{ ct.Name1 }}-{{ ct.Name2 }}
                  </option>
                </select>
              </el-col>
              <el-col :offset="4" :span="4" class="text-center">
                <el-button
                  v-if="currpage == 'list'"
                  type="primary"
                  @click="add_bom"
                  v-acl:BOMEdit.enable.show
                >
                  {{ $t("Add BOM") }}</el-button>
              </el-col>
            </el-row>
          </b-form>
        </b-card>
        <b-card class="mt-3">
          <div></div>
          <p class="p-3" v-if="productlist">
            <el-table
              stripe
              class="table-responsive table"
              header-row-class-name="thead-light"
              :data="productlist"
              @row-click="row_clicked_event"
            >
              <el-table-column label="PLU" prop="PLU"> </el-table-column>
              <el-table-column :label="$t('Name')">
                <template v-slot="{ row }">
                  {{ row.Name1 }}-{{ row.Name2 }}
                </template>
              </el-table-column>
              <el-table-column :label="$t('Package')">
                <template slot-scope="scope">
                  {{ display_package(scope.row) }}
                </template>
              </el-table-column>
              <el-table-column :label="$t('Type')">
                <template slot-scope="scope">
                  {{ scope.row.Type == 1 ? "BOM" : scope.row.Type == 2 ? $t("Dispose") : scope.row.Type == 3 ? $t("Combo") : "" }}
                </template>
              </el-table-column>
              <el-table-column :label="$t('Status')" prop="Status">
                <template slot-scope="scope">
                  {{ scope.row.Status == 1 ? "Active" : "Inactive" }}
                </template>
              </el-table-column>
              <el-table-column label="" v-if="hasUtilityAuth('BOMEdit')">
                <template>
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    size="mini"
                    circle
                    v-acl:BOMEdit.enable.show
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
            <b-pagination
              v-model="current_page"
              :total-rows="all_total"
              :per-page="per_page"
            ></b-pagination>
          </p>
          <p class="p-3" v-else>
            {{ $t("No Records") }}
          </p>
        </b-card>
      </div>
      <b-row v-if="currpage == 'edit'">
        <b-col>
          <b-card>
            <b-card :title="$t('Product')">
              <div v-if="cur_bom">
                <el-row :gutter="10">
                  <el-col align="right" :span="2"> PLU : </el-col>
                  <el-col :span="2">
                    {{ cur_bom.PLU }}
                  </el-col>
                  <el-col align="right" :span="2"> {{ $t("Name") }} : </el-col>
                  <el-col :span="4">
                    {{ cur_bom.Name1 }} - {{ cur_bom.Name2 }}
                  </el-col>
                  <!-- <el-col align="right" :span="2"> {{ $t("Unit") }} : </el-col>
                  <el-col :span="4">
                    {{ cur_bom.Unit }}
                  </el-col> -->
                  <el-col align="right" :span="2"> {{ $t("Package") }} : </el-col>
                  <el-col :span="2">
                    {{ display_package(cur_bom) }}
                  </el-col>
                  <el-col align="right" :span="2"> {{ $t("Type") }} : </el-col>
                  <el-col :span="4">
                    <el-select
                      size="mini"
                      v-model="cur_bom.Type"
                    >
                      <el-option :label="$t('Combo')" :value="3"> </el-option>
                      <el-option :label="$t('Dispose')" :value="2"> </el-option>
                      <el-option label="BOM" :value="1"> </el-option>
                    </el-select>
                  </el-col>
                  <!-- <el-col align="right" :span="2"> {{ $t("Status") }} : </el-col> -->
                  <el-col :span="3">
                    <el-switch
                      v-model="cur_bom.Status"
                      :active-text="$t('Status')"
                      :active-value="1"
                      :inactive-value="0"
                    >
                    </el-switch>
                  </el-col>
                  <el-col v-if="cur_opt == 'add'" :span="1">
                    <i
                      class="el-icon-error"
                      style="color: #f56c6c"
                      @click="remove_cur_bom"
                    ></i>
                  </el-col>
                </el-row>
              </div>
            </b-card>
            <b-card :title="$t('Sub Product')">
              <!-- && show_listed_products -->
              <div v-if="cur_subbom.length > 0">
                <el-table
                  stripe
                  class="table-responsive table mt-2"
                  style="width: 100%"
                  header-row-class-name="thead-light"
                  :data="cur_subbom"
                  @row-click="remove_subom_product"
                >
                  <el-table-column
                    min-width="10%"
                    label="PLU"
                    prop="PLU"
                  ></el-table-column>
                  <el-table-column min-width="55%" :label="$t('Name')">
                    <template v-slot="{ row }">
                      {{ row.Name1 }} - {{ row.Name2 }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    min-width="15%"
                    :label="$t('Package')"
                  >
                    <template slot-scope="scope">
                      {{ display_package(scope.row) }}
                    </template>
                  </el-table-column>
                  <el-table-column min-width="15%" :label="$t('Qty')">
                    <template slot-scope="scope">
                      <el-col :span="12">
                        <el-input v-model="scope.row.Qty" size="mini"></el-input>
                      </el-col>
                      <el-col :span="12" style="text-align: center;">
                        {{ display_qty_unit(scope.row) }}
                      </el-col>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="5%" label="">
                    <i class="el-icon-error" style="color: #f56c6c"></i>
                  </el-table-column>
                </el-table>
              </div>
            </b-card>
            <b-card><!--  title="Add Product" -->
              <section class="search_product_section">
                <!-- v-if="cur_listed_products.length > 0" -->
                <!-- && show_listed_products -->
                <el-row :gutter="5">
                  <el-col :span="6">
                    <b-input-group :prepend="$t('Department')+': '">
                      <select
                        class="form-control"
                        v-model="sch_department_IDn"
                        @change="change_sch_department"
                      >
                        <option :value="0"></option>
                        <option
                          v-for="ct in departmentlist"
                          :key="ct.IDn"
                          :value="ct.IDn"
                        >
                          {{ ct.Name1 }} - {{ ct.Name2 }}
                        </option>
                      </select>
                    </b-input-group>
                  </el-col>
                  <el-col :span="6">
                    <b-input-group :prepend="$t('Category')+': '">
                      <select
                        class="form-control"
                        v-model="sch_category_IDn"
                        @change="build_cur_products_delay"
                      >
                        <option :value="0"></option>
                        <option
                          v-for="ct in add_categorylist"
                          :key="ct.IDn"
                          :value="ct.IDn"
                        >
                          {{ ct.Name1 }} - {{ ct.Name2 }}
                        </option>
                      </select>
                    </b-input-group>
                  </el-col>
                </el-row>

                <el-table
                  stripe
                  class="table-responsive table product-table mt-2"
                  style="width: 100%"
                  header-row-class-name="thead-light"
                  :data="cur_listed_products"
                  @row-click="add_bom_product"
                >
                  <!-- <el-table-column min-width="10%" label="Department">
                    <template slot="header">
                      <div class="w-100 p-0 text-center labeldiv">Department</div>
                      <div class="my-select el-input el-input--mini el-input--suffix">
                        <select
                          class="el-input__inner"
                          size="mini"
                          v-model="sch_department_IDn"
                          @change="change_sch_department"
                        >
                          <option :value="0"></option>
                          <option 
                            v-for="ct in departmentlist"
                            :key="ct.IDn"
                            :value="ct.IDn"
                          >{{ ct.Name1 }}</option>
                        </select>
                      </div>
                    </template>
                    <template slot-scope="scope">
                      {{ get_department_name(scope.row.DepartmentIDn) }}
                    </template>
                  </el-table-column> -->
                  <!-- <el-table-column min-width="10%" label="Category">
                    <template slot="header">
                      <div class="w-100 p-0 text-center labeldiv">Category</div>
                      <div class="my-select el-input el-input--mini el-input--suffix" v-if="add_categorylist && add_categorylist.length > 0">
                        <select
                          class="el-input__inner"
                          size="mini"
                          v-model="sch_category_IDn"
                          @change="build_cur_products_delay"
                        >
                          <option :value="0"></option>
                          <option 
                            v-for="ct in add_categorylist"
                            :key="ct.IDn"
                            :value="ct.IDn"
                          >{{ ct.Name1 }}</option>
                        </select>
                      </div>
                    </template>
                    <template slot-scope="scope">
                      {{ get_category_name(scope.row.CategoryIDn) }}
                    </template>
                  </el-table-column> -->
                  <el-table-column min-width="10%" label="PLU" prop="PLU">
                    <template slot="header">
                      <div class="w-100 p-0 text-center labeldiv">PLU</div>
                      <div class="p-0 el-input el-input--mini">
                        <input
                          type="text"
                          autocomplete="off"
                          class="el-input__inner"
                          v-model="sch_PLU"
                          @keyup="build_cur_products_delay"
                        />
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="20%" :label="$t('Name') + '1'" prop="Name1">
                    <template slot="header">
                      <div class="w-100 p-0 text-center labeldiv">{{ $t('Name') + '1' }}</div>
                      <div class="p-0 el-input el-input--mini">
                        <input
                          type="text"
                          autocomplete="off"
                          class="el-input__inner"
                          v-model="sch_name1"
                          @keyup="build_cur_products_delay"
                        />
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="20%" :label="$t('Name') + '2'" prop="Name2">
                    <template slot="header">
                      <div class="w-100 p-0 text-center labeldiv">{{ $t('Name') + '2' }}</div>
                      <div class="p-0 el-input el-input--mini">
                        <input
                          type="text"
                          autocomplete="off"
                          class="el-input__inner"
                          v-model="sch_name2"
                          @keyup="build_cur_products_delay"
                        />
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="10%" :label="$t('Barcode')" prop="BarCode">
                    <template slot="header">
                      <div class="w-100 p-0 text-center labeldiv">{{ $t('Barcode') }}</div>
                      <div class="p-0 el-input el-input--mini">
                        <input
                          type="text"
                          autocomplete="off"
                          class="el-input__inner"
                          v-model="sch_barcode"
                          @keyup="build_cur_products_delay"
                        />
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="5%" label="">
                    <template>
                      <i class="el-icon-circle-plus" style="color: #409eff"></i>
                    </template>
                  </el-table-column>
                </el-table>
              </section>
            </b-card>
            <hr class="solid mb-2 mt-2" />
            <!-- product search and add -->
            <el-row :gutter="30">
              <el-col :span="4" :offset="16">
                <el-button type="primary" @click="form_submit">
                  {{ cur_opt == "add" ? $t("Add") : $t("Update") }}
                </el-button>
              </el-col>
              <el-col :span="4">
                <el-button @click="form_cancel"> {{ $t("Cancel") }} </el-button>
              </el-col>
            </el-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Button,
  Pagination,
  Collapse,
  Select,
  Option,
  CollapseItem,
  Row,
  Col,
  Switch,
} from "element-ui";
import { getStore, setStore } from "../../util/helper";
import { hasUtilityAuth } from "../../directives/acl";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [Select.name]: Select,
    [Option.name]: Option,
    [CollapseItem.name]: CollapseItem,
    [Row.name]: Row,
    [Col.name]: Col,
    [Switch.name]: Switch,
  },
  data() {
    return {
      activeNames: [],
      currpage: "list",
      cur_bom: null,
      cur_subbom: [],
      cur_opt: null,

      idxproducts: null,
      departmentlist: [],
      categorylist: [],
      add_categorylist: [],

      productlist: null,
      cur_listed_products: [],
      sch_department_IDn: 0,
      sch_category_IDn: 0,
      sch_PLU: "",
      sch_name1: "",
      sch_name2: "",
      sch_barcode: "",

      cur_department_IDn: 0,
      cur_category_IDn: 0,
      cur_PLU: "",
      cur_name: "",

      dismessage: "",
      countdown: 0,

      current_page: 1,
      all_total: 0,
      per_page: 20,

      delay_timer: null,

      token: "",
      user_id: 0,
    };
  },
  watch: {
    current_page: function (newval, oldval) {
      this.get_product_list(0);
    },
  },
  computed: {
    apiBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    },
  },
  methods: {
    hasUtilityAuth,
    display_qty_unit(product) {
      return (
          product.Weigh.toLowerCase() == "true" || 
          product.Weigh.toLowerCase() == "yes" || 
          product.Weigh.toLowerCase() == "y"
        ) ?
        (product.PackageWeightUnit ? product.PackageWeightUnit : "") :
        (
          product.PackageWeightUnit ? product.PackageWeightUnit : (
            product.Unit ? product.Unit : (
              product.BoxSize ? product.BoxSize : ""
            )
          )
        );
    },
    display_package(product) {
      if (
        product.Weigh.toLowerCase() == "true" || 
        product.Weigh.toLowerCase() == "yes" || 
        product.Weigh.toLowerCase() == "y"
      ) {
        return product.PackageWeightUnit ? product.PackageWeightUnit : "";
      } else {
        let box_size = "", pack_lms = "", unit = "", package_weight = "", package_unit = "";
        let t_1 = "", t_2 = "";
        
        box_size = product.BoxSize ? product.BoxSize : "";
        unit = product.Unit ? product.Unit : "";
        package_weight = product.PackageWeight && product.PackageWeight != 1 ? product.PackageWeight : "";
        package_unit = product.PackageWeightUnit ? product.PackageWeightUnit : "";
        if (
          product.PackL && product.PackM && product.PackS && 
          (product.PackL != 1 || product.PackM != 1 || product.PackS != 1)
        ) {
          pack_lms = (product.PackL + "X" + product.PackM + "X" + product.PackS);
        }
        // t_1
        // t_1 = (pack_lms && box_size ? (box_size == unit ? (pack_lms == unit ? "") : pack_lms + "/" + box_size) : pack_lms + box_size);
        if (box_size) {
          if (unit) {
            if (box_size == unit) {
              if (pack_lms) {
                t_1 = pack_lms + " " + unit;
              }
            } else {
              if (pack_lms) {
                t_1 = pack_lms + " " + unit + "/" + box_size;
              } else {
                t_1 = unit + "/" + box_size;
              }
            }
          } else {
            if (pack_lms) {
              t_1 = pack_lms + "/" + box_size;
            } else {
              unit = box_size;
            }
          }
        } else {
          if (unit) {
            if (pack_lms) {
              t_1 = pack_lms + " " + unit;
            }
          } else {
            t_1 = pack_lms;
          }
        }

        // t_2
        // t_2 = (package_weight && unit ? (unit == package_unit ? package_weight : package_weight + "/" + unit) : package_weight + unit);
        if (unit) {
          if (package_unit) {
            if (unit == package_unit) {
              if (package_weight) {
                t_2 = package_weight + " " + package_unit;
              } else {
                t_2 = package_unit;
              }
            } else {
              if (package_weight) {
                t_2 = package_weight + " " + package_unit + "/" + unit;
              } else {
                t_2 = package_unit + "/" + unit;
              }
            }
          } else {
            if (package_weight) {
              t_2 = package_weight + "/" + unit;
            } else {
              t_2 = unit;
            }
          }
        } else {
          if (package_unit) {
            if (package_weight) {
              t_2 = package_weight + " " + package_unit;
            } else {
              t_2 = package_unit;
            }
          } else {
            t_2 = package_weight;
          }
        }
        return t_1 && t_2 ? t_1 + ", " + t_2 : t_1 + t_2;

        // let rtn = "";

        // if (product.BoxSize && product.BoxSize != product.Unit) {
        //   console.log(product.BoxSize, product.Unit);
        //   rtn += (rtn ? (" " + product.BoxSize) : product.BoxSize);
        // }
        // if (
        //   (product.PackL && product.PackL != 1) || 
        //   (product.PackM && product.PackM != 1) || 
        //   (product.PackS && product.PackS != 1)
        // ) {
        //   let pack = product.PackL + "X" + product.PackM + "X" + product.PackS;
        //   rtn += (rtn ? (" " + pack) : pack);
        // }
        // if (product.Unit && product.Unit != product.PackageWeightUnit) {
        //   rtn += (rtn ? (" " + product.Unit) : product.Unit);
        // }
        // if (
        //   (product.PackageWeight && product.PackageWeight != 1) || 
        //   product.PackageWeightUnit
        // ) {
        //   let pack = 
        //     (
        //       (product.PackageWeight && product.PackageWeight != 1) ? 
        //       product.PackageWeight : ""
        //     ) + 
        //     (product.PackageWeightUnit ? product.PackageWeightUnit : "");
        //   rtn += (rtn ? (" " + pack) : pack);
        // }
        // return rtn;
      }
    },
    close_alert(countDownChanged) {
      this.countdown = countDownChanged;
      if (this.countdown == 0) {
        this.dismessage = "";
      }
    },
    remove_subom_product(row, column, event) {
      // console.log("remove_subom_product 1",row, column, event);
      if (column.label != "") {
        // Do nothing
        return;
      }
      for (var i = 0; i < this.cur_subbom.length; i++) {
        if (this.cur_subbom[i].IDn == row.IDn) {
          this.cur_subbom.splice(i, 1);
          break;
        }
      }
    },
    add_bom_product(row, column, event) {
      // console.log("add_bom_product 1",row, column, event);
      if (this.cur_bom) {
        if (this.cur_bom.ProductIDn == row.IDn) {
          alert("SubProduct can not same as Product");
          return;
        }
        for (var i = 0; i < this.cur_subbom.length; i++) {
          if (this.cur_subbom[i].ProductIDn == row.IDn) {
            alert("SubProduct existed");
            return;
          }
        }
        let new_subbom = JSON.parse(JSON.stringify(row));
        new_subbom["ProductIDn"] = new_subbom["IDn"];
        new_subbom["Qty"] = 1;
        this.cur_subbom.push(new_subbom);
      } else {
        this.cur_bom = {
          IDn: 0,
          ProductIDn: 0,
          Status: 1,
          Type: 3,
        };
        this.cur_bom["ProductIDn"] = row["IDn"];
        this.cur_bom["PLU"] = row["PLU"];
        this.cur_bom["Barcode"] = row["Barcode"];
        this.cur_bom["Name1"] = row["Name1"];
        this.cur_bom["Name2"] = row["Name2"];
        this.cur_bom["Weigh"] = row["Weigh"];
        this.cur_bom["BoxSize"] = row["BoxSize"];
        this.cur_bom["PackL"] = row["PackL"];
        this.cur_bom["PackM"] = row["PackM"];
        this.cur_bom["PackS"] = row["PackS"];
        this.cur_bom["Unit"] = row["Unit"];
        this.cur_bom["PackageWeight"] = row["PackageWeight"];
        this.cur_bom["PackageWeightUnit"] = row["PackageWeightUnit"];
      }
    },
    remove_cur_bom() {
      this.cur_bom = null;
      this.cur_subbom = [];
    },
    add_bom() {
      // console.log("add_bom");
      this.currpage = "edit";
      this.cur_bom = null;
      this.cur_subbom = [];
      this.sch_department_IDn = 0;
      this.sch_category_IDn = 0;
      this.sch_PLU = "";
      this.sch_name1 = "";
      this.sch_name2 = "";
      this.sch_barcode = "";
      this.change_sch_department();
      this.cur_opt = "add";
    },
    get_department_name(IDn) {
      if (this.departmentlist.length > 0) {
        for (var i=0; i<this.departmentlist.length; i++) {
          if (this.departmentlist[i].IDn == IDn) {
            return this.departmentlist[i].Name1;
          }
        }
      }
      return "";
    },
    get_category_name(IDn) {
      if (this.add_categorylist.length > 0) {
        for (var i=0; i<this.add_categorylist.length; i++) {
          if (this.add_categorylist[i].IDn == IDn) {
            return this.add_categorylist[i].Name1;
          }
        }
      }
      return "";
    },
    row_clicked_event(row, column, event) {
      // console.log("row_clicked_event", row, column, event);
      if (!hasUtilityAuth('BOMEdit')) return;
      this.currpage = "edit";
      this.cur_bom = JSON.parse(JSON.stringify(row));
      this.cur_subbom = [];
      this.sch_department_IDn = 0;
      this.sch_category_IDn = 0;
      this.sch_PLU = "";
      this.sch_name1 = "";
      this.sch_name2 = "";
      this.sch_barcode = "";
      this.change_sch_department();
      this.cur_opt = "update";
      var bodyFormData = new FormData();

      // Get sub product
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("IDn", this.cur_bom.IDn);
      bodyFormData.append("ProductIDn", this.cur_bom.ProductIDn);
      bodyFormData.append("Type", this.cur_bom.Type);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/bom/subproduct",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_subbom = rt.data.productlist;
              //that.$router.push("/store");
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    form_cancel() {
      this.currpage = "list";
    },
    async form_submit() {
      if (!this.cur_bom) {
        alert("Please add Product");
        return;
      }
      if (!this.cur_subbom || this.cur_subbom.length < 1) {
        alert("Please add SubProduct");
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      // bodyFormData.append("opt", this.cur_opt);
      // bodyFormData.append("ProductIDn", this.cur_bom.IDn);
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);
      for (let p in this.cur_bom) {
        if (this.cur_bom[p]) {
          bodyFormData.append(p, this.cur_bom[p]); 
        } else if (p == "Status") {
          bodyFormData.append(p, this.cur_bom[p]); 
        }
        // else {
        //   console.log("cur_bom p : ", p);
        // }
      }

      // var SubProduct = [];
      // for (var i = 0; i < this.cur_subbom.length; i++) {
      //   SubProduct.push({
      //     SubProductIDn: this.cur_subbom[i].IDn,
      //     Qty: this.cur_subbom[i].Qty,
      //   });
      // }
      // bodyFormData.append("SubProduct", JSON.stringify(SubProduct));
      bodyFormData.append("products", JSON.stringify(this.cur_subbom));
      let sch = {
        "DepartmentIDn": this.cur_department_IDn,
        "CategoryIDn": this.cur_category_IDn,
        "PLU": this.cur_PLU,
        "Name": this.cur_name,
      };
      bodyFormData.append("search_parameters", JSON.stringify(sch));

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/bom/update",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.dismessage = that.$t("Data Updated Successfully");
              that.countdown = 3;
              // that.get_product_list(0);
              if (rt.data.bomproducts) that.productlist = rt.data.bomproducts;
              if (rt.data.all_total) that.all_total = rt.data.all_total;
              if (rt.data.per_page) that.per_page = rt.data.per_page;
              that.currpage = "list";
              //that.$router.push("/store");
              //that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    bulid_department() {
      this.cur_department_IDn = 0;
      this.departmentlist = null;
      this.$nextTick(() => {
        this.departmentlist = this.idxproducts;
      });
      this.bulid_category();
    },
    bulid_category() {
      this.cur_category_IDn = 0;
      this.categorylist = [];
      var list = [];
      if (this.cur_department_IDn > 0) {
        for (let key in this.idxproducts) {
          if (this.idxproducts[key].IDn == this.cur_department_IDn) {
            list = this.idxproducts[key].Categorys;
            break;
          }
        }
      } else {
        for (let key in this.idxproducts) {
          for (let key2 in this.idxproducts[key].Categorys) {
            list.push(this.idxproducts[key].Categorys[key2]);
          }
        }
      }
      if (list) {
        this.$nextTick(() => {
          this.categorylist = list;
        });
      }
      this.get_product_list_delay();
    },
    change_sch_department() {
      this.sch_category_IDn = 0
      this.add_categorylist = [];
      var list = null;
      if (this.sch_department_IDn > 0) {
        for (let key in this.idxproducts) {
          if (this.idxproducts[key].IDn == this.sch_department_IDn) {
            list = this.idxproducts[key].Categorys;
            break;
          }
        }
      } else {
        list = [];
        for (let key in this.idxproducts) {
          for (let key2 in this.idxproducts[key].Categorys) {
            list.push(this.idxproducts[key].Categorys[key2]);
          }
        }
      }
      if (list) {
        this.$nextTick(() => {
          this.add_categorylist = list;
          this.build_cur_products();
       });
      } else {
        this.build_cur_products();
      }
    },
    build_cur_products_delay() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }
      var that = this;
      this.delay_timer = setTimeout(() => {
        that.build_cur_products();
      }, 500);
    },
    build_cur_products() {
      this.cur_listed_products = [];
      var list = [];
      if (this.sch_department_IDn > 0) {
        for (let key in this.idxproducts) {
          if (this.idxproducts[key].IDn == this.sch_department_IDn) {
            if (this.sch_category_IDn > 0) {
              for (let key2 in this.idxproducts[key].Categorys) {
                if (
                  this.idxproducts[key].Categorys[key2].IDn ==
                  this.sch_category_IDn
                ) {
                  for (let key3 in this.idxproducts[key].Categorys[key2].Products) {
                    if (this.idxproducts[key].Categorys[key2].Products[key3]["HasSerialNumber"]) continue;
                    list.push(this.idxproducts[key].Categorys[key2].Products[key3]);
                  }
                  break;
                }
              }
            } else {
              for (let key2 in this.idxproducts[key].Categorys) {
                for (let key3 in this.idxproducts[key].Categorys[key2].Products) {
                  if (this.idxproducts[key].Categorys[key2].Products[key3]["HasSerialNumber"]) continue;
                  list.push(this.idxproducts[key].Categorys[key2].Products[key3]);
                }
              }
            }
            break;
          }
        }
      } else {
        for (let key in this.idxproducts) {
          for (let key2 in this.idxproducts[key].Categorys) {
            if ((this.sch_category_IDn > 0) && (this.sch_category_IDn != this.idxproducts[key].Categorys[key2].IDn)) {
              continue;
            }
            for (let key3 in this.idxproducts[key].Categorys[key2].Products) {
              if (this.idxproducts[key].Categorys[key2].Products[key3]["HasSerialNumber"]) continue;
              list.push(this.idxproducts[key].Categorys[key2].Products[key3]);
            }
          }
        }
      }
      if (list) {
        var len = list.length;
        if (this.sch_PLU) {
          for (len--; len >= 0; len--) {
            if (list[len].PLU.indexOf(this.sch_PLU) < 0) {
              list.splice(len, 1);
            }
          }
        }
        if (this.sch_name1) {
          len = list.length;
          for (len--; len >= 0; len--) {
            if (list[len].Name1.indexOf(this.sch_name1) < 0) {
              list.splice(len, 1);
            }
          }
        }
        if (this.sch_name2) {
          len = list.length;
          for (len--; len >= 0; len--) {
            if (list[len].Name2.indexOf(this.sch_name2) < 0) {
              list.splice(len, 1);
            }
          }
        }
        if (this.sch_barcode) {
          len = list.length;
          for (len--; len >= 0; len--) {
            if (list[len].Barcode.search(this.sch_barcode) < 0) {
              list.splice(len, 1);
            }
          }
        }
        this.$nextTick(() => {
          this.cur_listed_products = list;
        });
      }
    },
    get_product_list_delay() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }
      var that = this;
      this.delay_timer = setTimeout(() => {
        that.get_product_list(0);
      }, 400);
    },
    async get_product_list(init) {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      if (this.cur_department_IDn) {
        bodyFormData.append("DepartmentIDn", this.cur_department_IDn);
      }
      if (this.cur_category_IDn) {
        bodyFormData.append("CategoryIDn", this.cur_category_IDn);
      }
      if (this.cur_PLU) {
        bodyFormData.append("PLU", this.cur_PLU);
      }
      if (this.cur_name) {
        bodyFormData.append("Name", this.cur_name);
      }
      bodyFormData.append("init", init);
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);
      if (this.PrinterIDn) {
        bodyFormData.append("PrinterIDn", this.PrinterIDn);
      }
      if (this.IsCombo >= 0) {
        bodyFormData.append("IsCombo", this.IsCombo);
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Bom",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.productlist = rt.data.bomproducts;
              that.all_total = rt.data.all_total;
              that.per_page = rt.data.per_page;
              if (init) {
                that.idxproducts = rt.data.idxproducts;
                that.bulid_department();
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      return this.get_product_list(1);
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 40px;
}
.search-button {
  margin-top: 2rem;
}
.select_text {
  margin-left: -1rem;
}
.card .table td {
  padding: 0.5rem;
}
.picimg {
  padding-left: 3rem;
  max-height: 6rem;
}
.el-collapse-item__header {
  background-color: #a5d1fa;
  font-size: 1rem;
  padding-left: 2rem;
  height: 2rem;
  border-radius: 0.75rem 0.75rem 0 0;
}
.el-collapse-item__content {
  padding-bottom: 0.5rem;
}
.form-group {
  margin-bottom: 0.5rem;
}
.search_product_section {
  max-height: 18rem;
  overflow: auto;
}
hr.solid {
  border-top: 1px solid #bbb;
}
.bompage .el-table th div {
  line-height: 1.5rem !important;
}

.my-select select {
  -moz-appearance:auto; /* Firefox */
  -webkit-appearance:auto; /* Safari and Chrome */
  appearance:auto;
}
/* .my-select::after {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #f00;
  position: absolute;
  top: 39%;
  right: 4px;
  content: "";
  z-index: 98;
} */
</style>
